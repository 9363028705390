@import "src/styles/index";

.nft-card {
    @extend %nft-card;

    &--is-disabled {
        opacity: .6;
        cursor: not-allowed;
    }

    &--is-highlighted {
        @extend %nft-card--is-highlighted;
    }

    &__img {
        width: 100%;
        aspect-ratio: 1;
        object-fit: contain;
    }

    &__header {
        display: flex;
        justify-content: space-between;
        gap: 1rem;
        position: absolute;
        top: 1rem;
        left: 1rem;
        width: calc(100% - 2rem);
    }

    &__label {
        @extend %status-label;
    }

    &__balance {
        @extend %balance-label;

        justify-self: flex-end;
        margin-left: auto;
    }

    &__info-wrapper {
        @extend %nft-card-info-wrapper;
    }

    &__name,
    &__price {
        @extend %text-ellipsis;

        line-height: 1.25;
    }

    &__name {
        @extend %nft-card-name;
    }

    &__price {
        @extend %nft-card-price;
    }

    &__expiry {
        @extend %nft-card-date;
    }
}
