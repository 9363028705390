@import "src/styles/index";

.nft-detail-attribute-card {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 1em;
    background: var(--c-grey);
    border-radius: .25rem; // Target 4px
    font-size: .75rem; // Target 12px

    @include for-size(desktop-up) {
        font-size: .875rem; // Target 14px
    }

    &__label,
    &__value {
        word-break: break-word;
    }

    &__label {
        color: var(--c-text-grey);
        font-weight: var(--fw-400);
    }

    &__value {
        font-weight: var(--fw-700);
    }
}