@import "src/styles";

.trade-nft-details {
    position: relative;
    width: 100%;

    &--has-failed-icon &__check-icon {
        width: 1.125rem;
        height: 1.125rem;
        color: var(--c-red);
    }

    &__check-icon-container {
        position: relative;
        height: 1.5rem;
        z-index: 1;
    }

    &__check-icon-circle {
        @extend %flex-align-center;

        border: 1px solid var(--c-dark-border);
        border-radius: 50%;
        margin: 0 auto;
        width: 3rem;
        aspect-ratio: 1;
        background: var(--c-black);
    }

    &__check-icon {
        width: 1.75rem;
        height: 1.75rem;
        color: var(--c-green);
    }

    &__inner {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        position: relative;
        border: 1px solid var(--c-dark-border);
        border-radius: .25rem;
        width: 100%;
        aspect-ratio: 1.09;
        padding: 1rem;
        background-size: cover;
        overflow: hidden;

        @include for-size(tiny-phone-only) {
            padding: .75rem;
        }

        &:after {
            @extend %after-block;

            left: 0;
            bottom: 0;
            width: 100%;
            height: 35%;
            background: linear-gradient(0deg, rgba(0, 0, 0, 0.5) 0%, rgba(0, 0, 0, 0) 100%);
            z-index: 1;
        }
    }

    &__token-name {
        position: relative;
        font-size: 1.125rem;
        font-weight: var(--fw-700);
        z-index: 2;
    }

    &__portrait-and-collection-details {
        display: flex;
        align-items: center;
        position: relative;
        margin-top: .5rem;
        z-index: 2;
    }

    &__collection-portrait {
        border-radius: 50%;
        margin-right: 1rem;
        width: 2.375rem;
        aspect-ratio: 1;
        background-size: cover;
        overflow: hidden;

        @include for-size(tiny-phone-only) {
            margin-right: .75rem;
            width: 2rem;
        }
    }

    &__collection-details {
        position: relative;
        width: calc(100% - 3.375rem);

        @include for-size(tiny-phone-only) {
            width: calc(100% - 3.625rem);
        }
    }

    &__collection-name {
        @extend %text-ellipsis;

        width: 100%;
        font-size: .875rem;
        font-weight: var(--fw-400);
    }
}
