@import "src/styles/index";

.wallet-connector {

    @include for-size(tablet-portrait-up) {
        display: flex;
        justify-content: center;
        padding-top: 1rem;

        &:before {
            content: '';
            display: block;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            background: var(--c-black);
            opacity: 0.75;
            z-index: 1;
        }
    }

    &__wrapper {
        width: 100%;
        height: 100%;
        padding: var(--page-padding);
        background: var(--c-black);
        z-index: 2;

        @include for-size(tablet-portrait-up) {
            border: 1px solid var(--c-dark-border);
            border-radius: .5rem;
            width: 18rem;
            height: 19rem;
            padding: 1rem;
            background-color: var(--c-dark);
        }
    }

    &__header {
        display: flex;
        justify-content: space-between;
    }

    &__title {
        font-size: 1.125rem;
    }

    &__close-button {
        width: 1.5rem;
        height: 1.5rem;
        color: var(--c-white);

        @include for-size(tablet-portrait-up) {
            width: 1.25rem;
            height: 1.25rem;
            color: var(--c-light-grey);
        }
    }

    &__wallet-providers-list {
        margin-top: var(--page-padding);

        @include for-size(tablet-portrait-up) {
            margin-top: 1rem;
        }
    }
}
