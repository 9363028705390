@import "src/styles/index";

.nft-detail-portrait {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    border-radius: var(--portrait-border-radius);
    width: 100%;
    aspect-ratio: 1;
    overflow: hidden;

    &__image {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-size: contain;
        background-position: 50% 50%;
        background-repeat: no-repeat;
        filter: drop-shadow(0px 1px 2px rgba(0, 0, 0, 0.25));
    }
}
