@import '../mixins';

%portrait {
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    position: relative;
    width: 100%;
    height: 100%;
    min-height: 15rem;
    max-height: 25rem;
    aspect-ratio: 1.5;
    padding: 1.5rem;
    background-size: cover;
    background-position: 50% 50%;

    @include for-size(desktop-up) {
        border-radius: var(--portrait-border-radius);
    }

    @include for-size(phone-only) {
        aspect-ratio: 3;
    }

    @include for-size(tablet-portrait-up) {
        aspect-ratio: 2;
    }

    &:after {
        display: block;
        content: '';
        position: absolute;
        bottom: 0;
        left: 0;
        width: 100%;
        height: 27.5%;
        background: linear-gradient(180deg, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0.64) 100%);
    }
}

%portrait-sub-title {
    position: relative;
    font-size: .75rem;
    font-weight: var(--fw-400);
    text-transform: uppercase;
    z-index: 1;
    opacity: .75;

    @include for-size(tablet-portrait-up) {
        font-size: 1rem;
    }
}