@import "src/styles";

.trade-details {
    @extend %trade-window;

    height: 4.5rem;

    @include for-size(tiny-phone-only) {
        padding: 0 .75rem;
    }

    &__icon {
        @extend %trade-window-icon;

        min-width: 2.375rem;

        @include for-size(tiny-phone-only) {
            min-width: 2rem;
        }
    }

    &__title-and-name {
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin: 0 1rem;

        @include for-size(tiny-phone-only) {
            margin: 0 .75rem;
        }
    }

    &__title {
        @extend %trade-window-title;
    }

    &__name {
        @extend %trade-window-name;

        margin-top: .5rem;
    }

    &__amount-container {
        display: block;
        position: relative;
        margin-left: auto;
        flex: 1 1 auto;
        height: 100%;
    }

    &__amount-container-content {
        display: flex;
        flex-direction: column;
        justify-content: center;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
    }

    &__amount {
        @extend %trade-window-amount;
    }

    &__amount-subtext {
        @extend %trade-window-sub-text;
    }
}
