@import "src/styles/index";

.nft-detail-sale-info {
    display: flex;
    justify-content: space-between;
    width: 100%;

    @include for-size(tiny-phone-only) {
        flex-direction: column;
    }

    @include for-size(tablet-portrait-up) {
        flex-direction: column;
    }

    &__price {
        font-family: var(--f-mono);
        font-size: 1rem;
        font-weight: var(--fw-400);

        @include for-size(tablet-portrait-up) {
            font-size: 2rem;
        }
    }

    &__expiry {
        font-size: .875rem;
        font-weight: var(--fw-400);
        color: var(--c-text-grey);

        @include for-size(tablet-portrait-up) {
            margin-top: .375rem;
        }
    }

    &__expiry-date {
        margin-left: .3125rem;
        font-size: .875rem;
        font-weight: var(--fw-700);
        color: var(--c-white);
    }
}
