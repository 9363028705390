@import "src/styles/index";

.wallet-provider-list {

    &__button {
        width: 100%;
        height: 4.5rem;
    }

    &__button + &__button {
        margin-top: .5rem;
    }
}
