@import "src/styles/index";

.nft-detail-main-info {

    &__owner {
        display: flex;
        margin-top: 1rem;
    }

    &__owner-label {
        width: fit-content;
        color: var(--c-text-grey);
    }
    
    &__owner-name {
        @extend %external-link;

        width: fit-content;
    }

    &__owners-button {
        @extend %button-border-style;

        margin-left: .25rem;
        padding: 0;
    }

    &__description {
        display: none;

        @include for-size(tablet-portrait-up) {
            display: block;
            margin-block-start: 2rem;
        }
    }

    &__sub-title {
        font-size: 1rem;
        color: var(--c-text-grey);
    }

    &__paragraph {
        margin-block-start: .75rem;
    }
}
