@import "src/styles";

.list-nft-widget {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
    background: var(--c-black);

    @include for-size(tablet-portrait-up) {
        border: 1px solid var(--c-dark-border);
        border-radius: .5rem;
        padding: 2rem var(--page-padding) var(--page-padding);
        background: var(--c-black);
    }

    &__trade-details-container {
        margin: 1.75rem 0 1.25rem;
    }

    &__no-tokens {
        margin-top: 1.75rem;
        border: 1px solid var(--c-dark-border);
        border-radius: .125rem;
        padding: 1rem 1.5rem;
    }

    &__no-tokens-title {
        font-weight: var(--fw-700);
    }

    &__no-tokens-subtitle {
        font-weight: var(--fw-400);
        font-size: .875rem;
        color: var(--c-text-grey);
    }

    &__action-buttons {
        justify-self: flex-end;
        margin-top: auto;
        width: 100%;
    }

    &__loading-spinner {
        margin: 3.5rem auto 0;
    }
}
