@import "src/styles/index";

.collection-portrait {
    @extend %portrait;

    &__title {
        position: relative;
        z-index: 1;
    }

    &__sub-title {
        @extend %portrait-sub-title;
    }
}
