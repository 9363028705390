@import "src/styles";

.review-nft-details {
    @extend %trade-window;

    height: 4.5rem;
    background: var(--c-black);

    &__title {
        @extend %trade-window-title;

        margin-right: auto;
    }

    &__name {
        @extend %trade-window-name;

        margin-left: 1rem;
        max-height: 2.5rem;
        line-height: 1.25;
        text-align: right;
        word-wrap: break-word;
        overflow: hidden;
    }

    &__icon {
        @extend %trade-window-icon;

        margin-left: 1rem;
    }
}
