@import "src/styles";

.select-nft-list-button {
    --select-nft-list-button-balance-width: 0;

    @extend %button-border-style;

    display: flex;
    justify-content: flex-start;
    gap: 1rem;
    width: 100%;
    text-align: left;
    padding-block: .75rem;
    padding-left: .75rem;
    font-size: 1.125rem;
    font-weight: var(--fw-700);

    &:hover {
        background-color: var(--c-dark);
    }

    &--is-preview &__name {
        color: var(--c-light-grey);
    }

    &__loading-spinner,
    &__logo-icon {
        @extend %trade-window-icon;

        min-width: 2.375rem;
        max-width: 2.375rem;

        @include for-size(tiny-phone-only) {
            min-width: 2rem;
            max-width: 2rem;
        }
    }

    &__loading-spinner {
        padding: .375rem;
        background: none;
    }

    &__name-and-listed {
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin-top: -.125rem;
        max-width: calc(100% - 4rem - var(--select-nft-list-button-balance-width));
        flex-grow: 0;
        flex-shrink: 1;

        @include for-size(tiny-phone-only) {
            max-width: calc(100% - 3rem - var(--select-nft-list-button-balance-width));
        }
    }

    &__name {
        @extend %trade-window-name;
        @extend %text-ellipsis;

        min-height: 1.125rem;
    }

    &__listed {
        @extend %trade-window-title;

        margin-top: .125rem;
        text-align: start;
    }

    &__balance {
        @extend %balance-label;

        justify-self: flex-end;
        margin-left: auto;
    }
}