@import "src/styles/index";

.profile-navigation-nav-link {
    @extend %button-border-style;
    @extend %flex-align-center;

    border: solid 1px var(--c-grey);
    margin-left: -1px;
    width: inherit;
    height: 2rem;
    font-weight: 700;
    font-size: .75rem;
    line-height: 1.5rem;
    text-transform: uppercase;
    color: var(--c-light-grey);
    background: var(--c-dark);
    z-index: 1;

    &:focus {
        z-index: 2;
    }

    &:first-child {
        border-top-left-radius: .25rem;
        border-bottom-left-radius: .25rem;
    }

    &:nth-child(2) {
        display: none;
    }

    &:last-child {
        border-top-right-radius: .25rem;
        border-bottom-right-radius: .25rem;
    }

    &.active {
        background-color: var(--c-grey);
        color: #fff;
    }

    &[aria-disabled="true"] {
        color: var(--c-grey);
        pointer-events: none;
    }
}
