@mixin for-size($size) {
    @if $size == tiny-phone-only {
        @media (max-width: 320px) { @content; }
    } @else if $size == phone-only {
        @media (max-width: 480px) { @content; }
    } @else if $size == tablet-portrait-up {
        @media (min-width: 768px) { @content; }
    } @else if $size == tablet-landscape-up {
        @media (min-width: 1024px) { @content; }
    } @else if $size == desktop-up {
        @media (min-width: 1280px) { @content; }
    } @else if $size == big-desktop-up {
        @media (min-width: 1800px) { @content; }
    }
}
