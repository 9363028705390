@import "src/styles";

.list-nft-details-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    &--has-sign-state,
    &--has-approve-state {
        flex-grow: 1;
    }

    &--is-select-nft-focus &__select-nft {
        z-index: 2;
    }

    &__select-expiry {
        margin-top: 1rem;
    }

    &__swap-icon {
        margin-top: -.5rem;
    }

    &__expiry-indicator {
        margin-top: .5rem;
    }

    &__message {
        justify-self: center;
        margin: auto;
        width: 50%;
        min-width: 12rem;
        text-align: center;
        padding-bottom: 2rem;
        font-size: .875rem;
        font-weight: var(--fw-400);
        color: var(--c-text-grey);
    }

    &__loading-spinner,
    &__failed-icon,
    &__check-icon {
        margin: 0 auto 2rem;
    }

    &__failed-icon,
    &__check-icon {
        border: 1px solid var(--c-dark-border);
        border-radius: 50%;
        width: 3rem;
        height: 3rem;
        padding: .5rem;
        color: var(--c-green);
    }

    &__failed-icon {
        color: var(--c-red);
    }

    &__transaction-link,
    &__copy-link-button {
        margin-bottom: 1.25rem;
    }

    &__transaction-link {
        margin-top: 1rem;
    }

    &__copy-link-button {
        margin-top: 2.5rem;
    }

    &__warning {
        display: flex;
        align-items: flex-start;
        border: 1px solid var(--c-dark-border);
        margin-top: 1rem;
        padding: .875rem 1rem 1.25rem .875rem;
        font-size: .875rem;
        color: var(--c-white);
        background: var(--c-dark);
    }

    &__info-icon {
        margin-right: 1rem;
        line-height: 1.25;
        min-width: 1.25rem;
        min-height: 1.25rem;
        font-size: .875rem;
    }

    &__warning-title {
        font-size: 1rem;
        font-weight: var(--fw-700);
    }

    &__warning-text {
        margin-top: 1rem;
        color: var(--c-light-grey);
    }
}
