@import "../mixins";

// For some pages we have a widget cover another widget, like BuyNftWidget in front of NftDetailWidget,
// or ListNftWidget in front of ProfileWidget

$widget-width: 19.5rem;
$widget-height: 34.125rem;

%widget-over-widget {
    width: 100%;
    min-height: calc(100svh - var(--top-bar-height) - var(--page-padding) * 2);

    @include for-size(tablet-portrait-up) {
        min-height: $widget-height;
    }
}

%widget-under-widget {
    display: none;

    @include for-size(tablet-portrait-up) {
        display: block;
        position: absolute;
        width: 100%;
        height: 100%;
        overflow: hidden;

        &:after {
            display: block;
            content: '';
            position: fixed;
            top: 0;
            left: 0;
            width: 100vw;
            height: 100vh;
            height: 100dvh;
            background: rgba(0, 0, 0, 0.75);
            backdrop-filter: blur(2px);
        }
    }
}

%widget-over-widget-container {
    position: relative;
    margin: 0 auto;
    width: 100%;
    max-width: #{$widget-width};
    height: 100%;
    z-index: 3;
    background: var(--c-black);

    @include for-size(tablet-portrait-up) {
        display: flex;
        align-items: center;
        justify-content: center;
        max-width: calc(#{$widget-width} + var(--page-padding) * 2);
        min-height: calc(100svh - var(--desktop-top-bar-height) - var(--page-padding) * 2);
        background: none;
    }
}
