@import "src/styles/index";

.collection-widget {
    position: relative;

    &__content,
    &__subtitle,
    &__nfts-container {
        margin-top: 1.5rem;

        @include for-size(desktop-up) {
            margin-top: 2rem;
        }
    }

    &__content {
        padding: 0 var(--page-padding);

        @include for-size(desktop-up) {
            padding: 0;
        }
    }

    &__search-input {
        max-width: 23.5rem;

        @include for-size(phone-only) {
            max-width: 100%;
        }
    }

    &__subtitle {
        justify-self: start;
        font-weight: var(--fw-700);
        font-size: 1rem;

        @include for-size(desktop-up) {
            font-size: 1.25rem;
        }
    }

    &__filter-button {
        justify-self: end;
    }
}

