@import "src/styles/index";

.owned-nfts-container {

    &__tokens {
        @extend %nfts-grid;
    }

    &__loader,
    &__end-of-orders-icon {
        margin: 1rem auto 0;
    }

    &__end-of-orders-icon {
        min-width: 1.75rem;
        min-height: 1.75rem;
        color: var(--c-white);
        opacity: 0.5;
    }
}
