@import "src/styles/index";

.profile-header {

	&__portrait {
		@extend %portrait;
	}

	&__wallet-info {
		z-index: 1;
	}

	&__navigation-container {
		margin: var(--page-padding) var(--page-padding) 0;

		@include for-size(tablet-portrait-up) {
			display: flex;
			justify-content: center;
			margin: -1rem 0;
		}
	}

	&__navigation {

		@include for-size(tablet-portrait-up) {
			width: 19.5rem;
		}
	}
}
