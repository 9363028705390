@import "src/styles";

.orders-container {
    padding-bottom: var(--page-padding);

    &__orders {
        @extend %nfts-grid;

        width: 100%;
    }

    &__empty-state {
        margin: 5rem auto 0;
    }

    &__loading-spinner {
        margin: 1rem auto 0;
    }

    &__end-of-orders-icon-wrapper {
        display: flex;
        margin: 2rem auto 0;
        width: 2rem;
        aspect-ratio: 1;
    }

    &__end-of-orders-icon {
        min-width: 1.75rem;
        min-height: 1.75rem;
        color: var(--c-white);
        opacity: 0.5;
    }
}
