@import "src/styles";

$component: search-input;

.#{$component} {
    position: relative;
    height: 3rem;

    .#{$component}__input {
        width: 100%;
        height: 100%;
        padding: 0 1rem .125rem 2.875rem;

        &:focus .#{$component}__icon {
            color: var(--c-white);
        }
    }

    &__icon {
        position: absolute;
        top: 1rem;
        left: 1rem;
        width: 1.125rem;
        height: 1.125rem;
        color: var(--c-light-grey);
    }
}
