@import "src/styles/index";

.toasts-widget {
    display: flex;
    flex-direction: column;
    position: fixed;
    top: 0;
    right: 0;
    padding: var(--page-padding);
    width: 100vw;
    max-width: 23.5rem;
    z-index: 100;
    pointer-events: none;
}
