@import "src/styles";

.review-token-details {
    @extend %trade-window;

    flex-direction: column;
    padding: 0;
    background: var(--c-black);

    &__token-info,
    &__fees-info {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        width: 100%;
        padding: 1rem;
    }

    &__token-info {
        height: 4.5rem;
    }

    &__fees-info {
        border-top: 1px solid var(--c-dark-border);
        height: 2.5rem;
    }

    &__fees-text {
        margin-left: 1rem;
    }

    &__title {
        @extend %trade-window-title;

        justify-self: flex-start;
        margin-right: auto;
    }

    &__amount-sub-text,
    &__fees-text {
        @extend %trade-window-sub-text;
    }

    &__amount-wrapper {
        margin-left: 1rem;
        width: calc(100% - 5rem);
    }

    &__amount {
        @extend %trade-window-amount;

        margin-top: .25rem;
    }

    &__logo-icon {
        @extend %trade-window-icon;

        margin-left: 1rem;
    }

    &__information-icon {
        margin-left: .5rem;
        width: 1rem;
        height: 1rem;
        color: var(--c-text-grey);
    }
}
