@import "src/styles/index";

.nft-detail-list {
    display: flex;
    flex-direction: column;

    @include for-size(tablet-portrait-up) {
        border: 1px solid var(--c-dark-border);
        border-radius: .25rem;
        padding: 1rem 1.5rem;
    }

    &__item {
        display: flex;
        justify-content: space-between;
        line-height: 1.375;

        & + & {
            margin-top: .375rem;
        }
    }

    &__item-link {
        @extend %external-link;

        text-align: right;
    }

    &__item-link-truncated-address {

        @include for-size(tablet-portrait-up) {
            display: none;
        }
    }

    &__item-link-address {
        display: none;

        @include for-size(tablet-portrait-up) {
            display: block;
        }
    }
}
