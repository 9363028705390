@import "src/styles/index";

.nft-detail-widget {
    display: flex;
    flex-direction: column;
    gap: 1rem;

    @include for-size(tablet-portrait-up) {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-template-rows: auto auto;
        grid-gap: 1rem;
        grid-template-areas:
            "portrait main-info"
            "portrait main-info"
            "description description"
            "details details"
            "activity activity"
            "attributes attributes";
    }

    @include for-size(tablet-landscape-up) {
        grid-template-areas:
            "portrait main-info"
            "portrait main-info"
            "description main-info"
            "details main-info"
            "activity main-info"
            "attributes main-info";
    }

    &--is-disconnected {
        display: block;
    }

    &--has-button {
        padding-block-end: 4.5rem;

        @include for-size(tablet-portrait-up) {
            padding-block-end: 0;
        }
    }

    &__portrait {
        grid-area: portrait
    }

    &__main-info {
        grid-area: main-info;

        @include for-size(tablet-portrait-up) {
            padding-inline-start: 1rem;
        }

        @include for-size(tablet-landscape-up) {
            padding-block-start: 2.5rem;
            padding-inline-start: 2.5rem;
        }
    }

    &__main-info-price {
        display: none;

        @include for-size(tablet-portrait-up) {
            display: block;
            margin-block-start: 2rem;
        }
    }

    &__price {

        @include for-size(tablet-portrait-up) {
            display: none;
        }
    }

    &__description {
        grid-area: description;
        margin-block-start: 1rem;

        @include for-size(tablet-portrait-up) {
            display: none;
        }
    }

    &__attributes {
        grid-area: attributes;
    }

    &__details {
        grid-area: details;
    }

    &__activity {
        grid-area: activity;
    }

    &__loading-spinner {
        margin: 2rem auto 0;
    }

    &__meta-container {
        margin-bottom: 2rem;
    }

    &__meta-container-label {
        margin-bottom: .75rem;
        font-weight: var(--fw-700);
        font-size: 1.125rem;
        color: var(--c-light-grey);
    }
}
