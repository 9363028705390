@import "flex";
@import "misc";

%button {
    @extend %flex-align-center;

    margin: 0;
    padding: 1rem;
    font: inherit;
    font-size: 1rem;
    font-weight: 500;
    line-height: 1;
    cursor: pointer;
    background: none;

    &:hover,
    &:focus {
        outline: none;
    }

    &:disabled {
        color: var(--c-disabled-grey);
        cursor: not-allowed;
        opacity: 0.65;
    }
}

%button--is-primary {
    @extend %button;

    border-radius: 4px;
    height: 3rem;
    padding: .75rem;
    font-weight: var(--fw-700);
    font-size: .9375rem;
    text-transform: uppercase;
    background: var(--c-primary);
    color: var(--c-white);

    &:hover,
    &:focus {
        background: var(--c-primary-dark);
    }

    &:disabled {
        opacity: .65;
    }
}

%button--is-secondary {
    @extend %button--is-primary;

    border: 1px solid var(--c-grey);
    color: var(--c-white);
    background: var(--c-black);

    &:hover,
    &:focus {
        background: var(--c-dark);
    }

    &:focus {
        border-color: var(--c-light-grey);
    }
}

%button-border-style {
    border: 1px solid transparent;

    &:hover,
    &:focus {
        outline: none;
    }

    &:focus {
        border-color: var(--c-light-grey);
    }

    &:active {
        border-color: var(--c-primary);
    }
}

%icon-button {
    @extend %button;
    @extend %button-border-style;

    position: relative;
    padding: .25rem;
    font-weight: 600;
    box-sizing: border-box;
    border-radius: 2px;
}

%external-link {
    text-transform: lowercase;
    color: var(--c-primary-dark);

    &:hover,
    &:focus {
        text-decoration: underline;
    }
}
