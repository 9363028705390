%text-ellipsis {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
}

%after-block {
    content: '';
    display: block;
    position: absolute;
}

%scrollbar {
    padding-right: 1rem;

    &::-webkit-scrollbar {
        border-radius: 0.5rem;
        width: 0.375rem;
        background: var(--c-dark);
    }

    &::-webkit-scrollbar-thumb {
        background: var(--c-light-grey);
        border-radius: 0.5rem;
    }
}
