@import "src/styles";

.list-nft-page {
    position: relative;

    &__profile-widget {
        @extend %widget-under-widget;
    }

    &__list-nft-widget-container {
        @extend %widget-over-widget-container;
    }

    &__list-nft-widget {
        @extend %widget-over-widget;
    }
}
