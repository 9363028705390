@import "../../styles/index";

$component: page;

.#{$component} {
    position: relative;
    margin: 0 auto;
    width: 100%;
    min-width: 12.5rem;
    z-index: 80;

    @include for-size(desktop-up) {
        max-width: var(--page-max-width);
    }

    &--show-wallet-connector .#{$component}__wallet-connector {
        visibility: visible;
        pointer-events: visible;
    }

    &__top-bar {
        z-index: 5;
    }

    &__mobile-menu {
        position: fixed;
        left: 0;
        top: var(--top-bar-height);
        width: 100vw;
        height: calc(100svh - var(--top-bar-height));
        z-index: 5;

        @include for-size(tablet-portrait-up) {
            display: none;
        }
    }

    &__content {
        position: relative;
        padding: var(--page-padding);
    }

    &__wallet-connector {
        position: absolute;
        top: var(--top-bar-height);
        left: 0;
        width: 100%;
        height: calc(100% - var(--top-bar-height));
        min-height: calc(100svh - var(--top-bar-height));
        z-index: 4;
        overflow-y: auto;
        visibility: hidden;
        pointer-events: none;

        @include for-size(tablet-portrait-up) {
            top: var(--desktop-top-bar-height);
            height: calc(100% - var(--desktop-top-bar-height));
            min-height: calc(100svh - var(--desktop-top-bar-height));
        }
    }

    &__connect-wallet-button {
        @extend %button--is-primary;

        position: fixed;
        width: calc(100% - var(--page-padding) * 2);
        left: var(--page-padding);
        top: calc(100svh - 3rem - var(--page-padding));

        @include for-size(tablet-portrait-up) {
            display: none;
        }
    }
}
