%nfts-grid {
	display: grid;
	grid-column: 1 / 3;
	grid-template-columns: repeat(3, 1fr);
	gap: 1rem;

	@include for-size(phone-only) {
		grid-template-columns: 1fr 1fr;
		gap: .875rem;
	}

	@include for-size(tablet-portrait-up) {
		grid-template-columns: repeat(4, 1fr);
		gap: 1.25rem;
	}

	@include for-size(tablet-landscape-up) {
		gap: 1.5rem;
	}
}