@import "src/styles/index";

.profile-widget {
    padding-bottom: var(--page-padding);

    &__search-input {
        max-width: 23.5rem;

        @include for-size(phone-only) {
            max-width: 100%;
        }
    }

    &__content {
        margin-top: var(--page-padding);
        padding: 0 var(--page-padding);

        @include for-size(tablet-portrait-up) {
            margin-top: 2.5rem;
        }

        @include for-size(desktop-up) {
            margin-top: 3rem;
            padding: 0;
        }
    }

    &__nfts-container {
        margin-top: var(--page-padding);
    }

    &__empty-state,
    &__loading-spinner {
        margin: 2.5rem auto 0;
    }

    &__empty-state {
        opacity: 0.5;
    }
}
