@import "src/styles";

.wallet-info {
  display: flex;
  align-items: center;
  padding: 1rem;

  &--is-menu {
    background-color: var(--c-dark);
    border: 1px solid var(--c-dark-border);
    border-radius: 0.3125rem;
  }

  &__sub-title {
    @extend %portrait-sub-title;
  }

  &__avatar {
    min-width: 2.5rem;
    height: 2.5rem;
    margin-right: 0.875rem;
  }

  &__address-bar {
    display: flex;
    flex-direction: column;
    flex-grow: 1;
  }

  &__address {
    @extend %text-ellipsis;
    font-weight: var(--fw-700);
  }

  &__address-secondary {
    font-size: 0.75rem;
    font-weight: var(--fw-400);
  }

  &__icon {
    color: var(--c-white);
    width: 1.25rem;
    height: 1.25rem;
  }

  &__link,
  &__button {
    margin-left: .5rem;
  }
}
