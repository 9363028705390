@import "src/styles";

.copy-link-button {
    @extend %button-border-style;

    border: 1px solid var(--c-dark-border);
    border-radius: .25rem;
    padding: .675rem .75rem;
    text-transform: uppercase;
    font-size: .75rem;
    color: var(--c-white);
}
