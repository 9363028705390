@import "src/styles/index";

.cancel-action-buttons {
    display: flex;
    gap: 1rem;

    &__cancel-button,
    &__action-button,
    &__back-button {
        justify-self: flex-end;
        margin-top: auto;
        width: 100%;
    }

    &__cancel-button,
    &__action-button {
        @extend %button--is-primary;
    }

    &__back-button {
        @extend %button--is-secondary;
    }

    &__cancel-button {
        border: 1px solid var(--c-light-red);
        background: none;

        &:hover,
        &:focus {
            color: var(--c-black);
            background: var(--c-light-red);
        }
    }
}
