@import "src/styles/index";

.buy-action-buttons {

    &__action-button {
        @extend %button--is-primary;

        justify-self: flex-end;
        margin-top: auto;
        width: 100%;
    }
}
