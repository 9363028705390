@import "src/styles/index";

.nft-detail-proceed-button {
	position: fixed;
	bottom: 0;
	left: 0;
	width: calc(100svw - var(--scrollbar-width));
	height: calc(3rem + var(--page-padding) * 2);
	background-color: var(--c-grey);
	padding: var(--page-padding);
	z-index: 10;

	@include for-size(tablet-portrait-up) {
		position: relative;
		bottom: unset;
		left: unset;
		margin-block-start: 3rem;
		width: 12.1875rem;
		height: fit-content;
		padding: 0;
		background-color: transparent;
		z-index: unset;
	}

	&__link {
		@extend %button--is-primary;

		width: 100%;
	}
}