$c-primary: #2B71FF;
$c-primary-dark: #0752EB;
$c-white: #FFF;
$c-black: #000;
$c-kuroi-black: #101217;
$c-river-styx: #1A1E25;
$c-light-grey: #6E7686;
$c-quick-silver: #9EA3AC;
$c-green: #0ED48D;
$c-red: #FF0000;
$c-light-red: #FF6D64;

@mixin light-mode-variables {
    --c-primary:        #{$c-primary};
    --c-white:          #{$c-black};
    --c-black:          #{$c-white};
}

:root {
    --c-primary: #{$c-primary};
    --c-primary-dark: #{$c-primary-dark};
    --c-white: #{$c-white};
    --c-white-always: #{$c-white};
    --c-black: #{$c-black};
    --c-black-always: #{$c-black};
    --c-dark: #{$c-kuroi-black};
    --c-dark-border: #{$c-river-styx};
    --c-grey: #{$c-river-styx};
    --c-light-grey: #{$c-light-grey};
    --c-text-grey: #{$c-quick-silver};
    --c-green: #{$c-green};
    --c-red: #{$c-red};
    --c-light-red: #{$c-light-red};

    --f-primary: "loos-normal", sans-serif;
    --f-mono: "DM Mono", monospace;

    --page-max-width: 70rem;
    --page-padding: 1.5rem;
    --top-bar-height: 3rem;
    --desktop-top-bar-height: 5rem;
    --portrait-border-radius: .5rem;
    --scrollbar-width: calc(100svw - 100%);

    // loos-normal font-weights
    --fw-800: 800;
    --fw-700: 700;
    --fw-500: 500;
    --fw-400: 400;
    --fw-300: 300;
    --fw-200: 200;
    --fw-100: 100;

    // Disable this for now since the default theme is dark
    // @media (prefers-color-scheme: light) {
    //     @include light-mode-variables;
    // }

    &[data-theme="light"] {
        @include light-mode-variables;
    }

    --wcm-z-index: 100;
}
