@import "../../styles/index";

$component: select-nft-button;

.#{$component} {
    @extend %trade-window;

    justify-content: flex-start;
    position: relative;
    height: 4.5rem;
    overflow: visible;

    &:focus {
        border-color: var(--c-light-grey);
    }

    &:active {
        border-color: var(--c-primary);
    }

    @include for-size(tiny-phone-only) {
        padding: 0 .75rem;
    }

    .#{$component}__dropdown {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        cursor: pointer;
    }

    &__dropdown-button {
        width: 100%;
        opacity: 0;
    }

    &__dropdown-dropdown-button,
    &__dropdown-dropdown-button-background {
        height: 3.25rem;
    }

    &__dropdown-dropdown-button {
        line-height: 1;
        font-size: 1rem;
        font-weight: var(--fw-700);
        color: var(--c-white);
    }

    &__dropdown-mobile-select-icon {
        opacity: 0;
    }

    &__logo-icon {
        @extend %trade-window-icon;

        min-width: 2.375rem;

        @include for-size(tiny-phone-only) {
            min-width: 2rem;
        }
    }

    &__title-and-name {
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin: 0 1rem;
        max-width: calc(100% - 6rem);

        @include for-size(tiny-phone-only) {
            margin: 0 .75rem;
            max-width: calc(100% - 5rem);
        }
    }

    &__title {
        @extend %trade-window-title;

        text-align: start;
    }

    &__name {
        @extend %trade-window-name;
        @extend %text-ellipsis;

        margin-top: .125rem;
        min-height: 1.125rem;
    }

    &__chevron-down-icon {
        justify-self: flex-end;
        margin-left: auto;
        width: 1.5rem;
        height: 1.5rem;
    }
}
