@import "src/styles/index";

$component: top-bar;

.#{$component} {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    height: var(--top-bar-height);
    padding: 0.5rem;
    background: var(--c-grey);
    position: relative;

    @include for-size(tablet-portrait-up) {
        height: var(--desktop-top-bar-height);
        background: none;

        &__menu-button {
            display: none;
        }

        .#{$component}__airswap-button {
            height: 3rem;
        }

        .#{$component}__list-button  {
            border-color: var(--c-grey);
            height: 2.5rem;
            font-size: .9375rem;
        }
    }

    @include for-size(desktop-up) {
        padding: 0 0 0 .5rem;
    }

    &--mobile-menu-is-visible {
        background: var(--c-black);

        .#{$component}__menu-button-icon {
            width: .875rem;   
        }
    }

    &__menu-button,
    &__airswap-button {
        height: 2rem;
        aspect-ratio: 1;
        color: var(--c-white);
    }

    &__menu-button {
        position: absolute;
        left: calc(50% - 1rem);

        @include for-size(tablet-portrait-up) {
            display: none;
        }
    }

    &__desktop-nav {
        display: none;

        @include for-size(tablet-portrait-up) {
            display: flex;
            margin-right: -5%;
        }

        @include for-size(tablet-landscape-up) {
            margin-right: -10%;
        }

        @include for-size(desktop-up) {
            position: absolute;
            left: 50%;
            margin: 0;
            transform: translateX(-50%);
        }
    }

    &__list-button-and-wallet-button-wrapper {
        display: flex;
        align-items: center;
        height: 100%;
    }

    &__list-button  {
        border: 1px solid var(--c-light-grey);
        border-radius: .25rem;
        height: 100%;
        padding: 0 .75rem;
        text-transform: uppercase;
        font-size: .75rem;
        font-weight: var(--fw-700);
        color: var(--c-white);

        &:focus {
            border: 1px solid var(--c-primary);
        }

        &[aria-disabled="true"] {
            opacity: 0.5;
        }

        @include for-size(tablet-portrait-up) {
            padding: 0 1.25rem;
        }
    }

    &__list-button-icon {
        width: 0.75rem;
        height: 0.75rem;
    }

    &__connect-button {
        @extend %button--is-primary;

        display: none;
        margin-left: 1rem;
        height: 2.5rem;
        padding: 0 1rem;

        @include for-size(tablet-portrait-up) {
            display: flex;
        }
    }

    &__user-button {
        @extend %button;
        @extend %button-border-style;

        display: none;
        height: 2.5rem;
        padding: 0 .5rem;
        font-weight: var(--fw-500);
        color: var(--c-white);

        @include for-size(tablet-portrait-up) {
            display: flex;
        }
    }

    &__user-button-icon {
        width: 2.5rem;
        height: 2.5rem;
        margin-right: 0.75rem;
        border: 1px solid var(--c-dark-border);
        border-radius: 100%;
        color: var(--c-black);
    }

    &__user-popup {
        position: absolute;
        right: 1rem;
        top: calc(var(--desktop-top-bar-height) - .5rem);

        @include for-size(desktop-up) {
            right: -2rem;
        }
    }
}